const data = {
  'work-experience': [
    {
      title: 'D2L',
      role: 'Web Developer',
      description: '',
      start: 'Jan 2019',
      icon: ''
    },
    {
      title: 'NovaSans',
      role: 'Web Developer',
      description: '',
      start: 'May 2015',
      end: 'June 2020',
      icon: ''
    },
    {
      title: 'Fanshawe College',
      role: 'Peer Tutor',
      description: '',
      start: 'Sep 2014',
      end: 'Apr 2015'
    },
    {
      title: 'Campus Creative',
      role: 'Graphic Designer/ Web Developer',
      description: '',
      start: 'Jan 2014',
      end: 'Sep 2014',
      icon: ''
    }
  ],
  'skills': [
    // {
    //   category: 'Soft Skills',
    //   color: '',
    //   items: [
    //     'Problem Solving',
    //     'Approachable',
    //     'Patient',
    //     'Accountable',
    //     'Respectul',
    //     'Inquisitive'
    //   ]
    // },
    {
      category: 'JavaScript',
      color: '',
      items: [
        'ES6',
        'Node.js',
        'React',
        'Vue.js',
      ]
    },
    {
      category: 'PHP',
      color: '',
      items: [
        'WordPress',
        'WooCommerce',
        'Laravel'
      ]
    },
    {
      category: 'Development Tools',
      color: '',
      items: [
        'Git',
        'Webpack',
        'Vite',
        'Docker',
        'Visual Studio Code',
        'Bash/Unix Shell'
      ]
    },
    {
      category: 'Database',
      color: '',
      items: [
        'MongoDB',
        'Firebase',
        'SQL'
      ]
    },
    {
      category: 'CI/CD',
      color: '',
      items: [
        'GitHub Actions',
        'CircleCI'
      ]
    },
    {
      category: 'Testing Tools/Frameworks',
      color: '',
      items: [
        'Mocha.js',
        'Chai.js',
        'Cypress.io',
        'Playwright'
      ]
    },
    {
      category: 'Misc',
      color: '',
      items: [
        'Adobe Photoshop',
        'Adobe Illustrator',
        'Shopify',
        'Figma'
      ]
    }
  ],
  'education': [
    {
      title: 'Fanshawe College',
      program: 'Advanced Diploma – Graphic Design',
      graduated: '2013',
      icon: ''
    }
  ]
};

export default data;
